import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Animation, Icon, ImageCore, LinkWrapper, Text } from '@components';
import { Box, Button } from '@core';
import { formatLink } from '@shared/format-helpers';
import checkMarkRound from '@images/check-mark-round.svg';
import { ONE_MONTH_PLAN } from '@shared/constants/checkout';

const Feature = ({ label, type }) => (
  <Box display="flex" mb={12}>
    <Box display="flex" alignItems="baseline" position="relative" top={2}>
      {type === 'feature' && <Icon.Check size={18} color="success" />}
      {type === 'nonFeature' && <Icon.Close size={20} color="darkBlue_40" />}
    </Box>
    <Text.Body4 ml={12} color="darkBlue">
      {label}
    </Text.Body4>
  </Box>
);

const DiscountStickerContainer = styled(Box)`
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
`;

const ComparisonCard = ({
  title,
  description,
  pricing,
  discountPercentage,
  features,
  isPremium,
  subscriptionPeriod,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <Box
      backgroundColor="white"
      boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
      py={16}
      px={{ _: 24, lg: 32 }}
      minHeight={{ _: 416, sm: 432, lg: 444 }}
      position="relative"
    >
      <Text.Heading2 textAlign="left" color="darkBlue">
        {title}
      </Text.Heading2>

      {discountPercentage && (
        <Animation.FadeIn>
          <DiscountStickerContainer backgroundColor="success" borderRadius="md" px={24}>
            <Text.Body2Strong color="white" whiteSpace="nowrap">
              {t('plans:checkoutCard.saveDiscount', {
                discountPercentage,
              })}
            </Text.Body2Strong>
          </DiscountStickerContainer>
        </Animation.FadeIn>
      )}

      <Box minHeight={40} mb={18}>
        <Text.Body4 as="div" textAlign="left" color="darkBlue">
          {description}
        </Text.Body4>
        <Text.Body4 textAlign="left" color="darkBlue">
          {pricing}
        </Text.Body4>
      </Box>

      <Box display="flex" flexDirection="column">
        {features.slice(0, 7).map((feature, index) => (
          <Feature {...feature} key={title + feature.type + index} />
        ))}
      </Box>

      <Box mb={12}>
        <Button type="button" variant="text" onClick={() => setExpanded(!expanded)}>
          {!expanded ? t('common:actions.seeMore') : t('common:actions.seeLess')}
        </Button>
      </Box>
      <Animation.Height visible={expanded}>
        <Box display="flex" flexDirection="column">
          {features.slice(7).map((feature, index) => (
            <Feature {...feature} key={title + index + feature.type} />
          ))}
        </Box>
      </Animation.Height>
      {isPremium ? (
        <Box mt={4}>
          <LinkWrapper link={formatLink(t('navigation:paths.get'))} withParams>
            <Button type="button" fullWidth variant="outlined">
              {t('common:actions.getPremium')}
            </Button>
          </LinkWrapper>

          <Box mt={8} display="flex" alignItems="center" justifyContent="center">
            <ImageCore src={checkMarkRound} alt="checkmark" />
            <Text.Body4 fontSize={10} lineHeight="16px" color="darkBlue" ml={8}>
              {subscriptionPeriod === ONE_MONTH_PLAN
                ? t('common:14dayMoneyBackGuarantee')
                : t('common:30dayMoneyBackGuarantee')}
            </Text.Body4>
          </Box>
        </Box>
      ) : (
        <Box mt={4} mb={24}>
          <LinkWrapper link={formatLink(t('navigation:paths.download'))} withParams>
            <Button type="button" fullWidth>
              {t('common:actions.getFree')}
            </Button>
          </LinkWrapper>
        </Box>
      )}
    </Box>
  );
};

export default ComparisonCard;
